<!-- @format -->

<template>
  <b-card class="mb-3" header-class="d-flex justify-content-between align-items-center">
    <!-- <b-form-select v-model="pageSize" :options="options" /> -->
    <b-row>
      <b-col md="2" class="mb-3">
        <div class="d-flex" style="align-items: center">
          <span style="width: 60px; display: flex">Pages:</span>
          <b-form-select v-model="pageSize" :options="options" />
        </div>
      </b-col>
      <!-- 时间区段选择 -->
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="year"
          :clearable="false"
          :options="years"
          :reduce="option => option.value"
          placeholder="Year"
          style="width:100%;"
        />
      </b-col>
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="month"
          :clearable="false"
          :options="months"
          :reduce="option => option.value"
          placeholder="Month"
          style="width:100%;"
          :disabled="!year"
        />
      </b-col>

      <!--      搜索id-->
      <b-col cols="7" class="d-flex">
        <div class="mr-1">
          <b-form-input v-model="id" class="d-inline-block" placeholder="Member id" />
        </div>
        <div>
          <b-form-input v-model="real_name" class="d-inline-block" placeholder="Member name" />
        </div>
      </b-col>
    </b-row>
    <b-tabs content-class="mt-3" @input="chan">
      <b-tab title="Personal TP">
        <b-table :fields="itemsPerson" :items="itemsPersonItem" empty-text="NO data" show-empty />
      </b-tab>
      <b-tab title="Baseshop TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="NO data" show-empty />
      </b-tab>
      <b-tab title="Superbase TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="NO data" show-empty />
      </b-tab>
      <b-tab title="Infinity TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="NO data" show-empty />
      </b-tab>
      <b-tab title="Recruiting">
        <b-table :fields="itemsPerson3" :items="itemsPersonItem1" empty-text="NO data" show-empty />
      </b-tab>
    </b-tabs>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="pageSize" aria-controls="my-table" class="my-0" align="center" />
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BTable, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { nextTick, reactive, ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTabs,
    BTab,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    vSelect
  },
  setup() {
    const years = [
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' }
    ]
    const months = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
    ]

    const itemsPerson = [
      {
        key: 'key',
        label: 'Rank',
      },
      {
        key: 'real_name',
        label: 'Agent',
      },
      {
        key: 'active_tp',
        label: 'INFORCE TP',
        formatter: value => 'Under Maintenance'
        // formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      },
      {
        key: 'pid',
        label: 'SPONSOR',
      },
      {
        key: 'parent_name',
        label: 'SPONSOR AGENT',
      },
    ]
    const itemsPerson2 = [
      {
        key: 'id',
        label: 'Rank',
      },
      {
        key: 'real_name',
        label: 'LEADER',
      },
      {
        key: 'active_tp',
        label: 'INFORCE TP',
        formatter: value => 'Under Maintenance'
        // formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      },
      {
        key: 'pid',
        label: 'SPONSOR',
      },
      {
        key: 'parent_name',
        label: 'SPONSOR AGENT',
      },
    ]
    const itemsPerson3 = [
      {
        key: 'rank',
        label: 'Rank',
      },
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'firstname',
        label: 'Name',
      },
      {
        key: 'childLen',
        label: 'Recruits',
      },
      {
        key: 'pid',
        label: 'Sponsor ID',
      },
      {
        key: 'parent_name',
        label: 'Sponsor Name',
        formatter: (value, row, record) => record.parent?.real_name
      },
    ]
    const itemsPersonItem = ref([])
    const itemsPersonItem1 = ref([])

    const normalTeamTp = [
      {
        key: 'id',
        label: 'Rank',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const superTeamTp = [
      {
        key: 'id',
        label: 'Rank',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const travTeamTp = [
      {
        key: 'id',
        label: 'Rank',
      },
      {
        key: 'name',
        label: 'Agent',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const tabs = reactive([
      { title: 'Personal TP', content: 'Tab 1 content' },
      { title: 'Baseshop TP', content: 'Tab 2 content' },
      { title: 'Superbase TP', content: 'Tab 3 content' },
      { title: 'Infinity TP', content: 'Tab 3 content' },
    ])

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altInput: true,
      dateFormat: 'Y-m',
    }

    const pageSize = ref(100)
    const currentPage = ref(1)
    const total = ref(0)
    const options = [
      { value: 10, text: '10' },
      { value: 20, text: '20' },
      { value: 50, text: '50' },
      { value: 100, text: '100' },
    ]

    // 四个表格的数据,四个方法调用
    const year = ref('')
    const month = ref('')
    const id = ref('')
    const real_name = ref('')
    const cate_id = ref('')
    const fetchData = async () => {
      nextTick(() => {
        axiosIns
        // .get('order/tp-all', {
          .get('order/tp-all', {
            params: {
              cate_id: cate_id.value,
              year: year.value,
              month: month.value,
              page_size: pageSize.value,
              page: currentPage.value,
              id: id.value,
              real_name: real_name.value,
            },
          })
          .then(res => {
            itemsPersonItem.value = res.data?.list
            total.value = res.data?.total
          })

        axiosIns
          .get('user/child-top', {
            params: {
              cate_id: cate_id.value,
              start_time: start_time.value,
              end_time: end_time.value,
              page_size: pageSize.value,
              page: currentPage.value,
              id: id.value,
              real_name: real_name.value,
            },
          })
          .then(res => {
            itemsPersonItem1.value = res.data
          })
      })
    }

    watch([year, month, pageSize, id, real_name, currentPage], () => {
      fetchData()
    })

    const chan = val => {
      cate_id.value = val + 1
      itemsPersonItem.value = []
      itemsPersonItem1.value = []
      fetchData()
    }
    return {
      cate_id,
      currentPage,
      total,
      config,
      fetchData,
      options,
      id,
      real_name,
      year,
      month,
      tabs,
      chan,
      pageSize,

      itemsPerson,
      itemsPersonItem,
      itemsPersonItem1,
      itemsPerson2,
      itemsPerson3,

      normalTeamTp,
      superTeamTp,
      travTeamTp,

      years,
      months,
    }
  },
}
</script>

<style lang="scss" scoped>
.tab-pane {
  overflow: auto;
}
</style>
